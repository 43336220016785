$primary: #4f2b80;
$link-decoration: none;

@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/css/all";
@import "~@fortawesome/fontawesome-free/css/brands";

// Klaro
$var-blue1: $primary;
$var-green1: $primary;
@import "~klaro/src/scss/klaro.scss";

// Custom

:root {
  --swiper-theme-color: #{$primary};
}

h2 {
  position: relative;
  text-align: center;
  font-weight: 700;
  padding-bottom: 15px;
  margin: 20px 0;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: $primary;
    bottom: 0;
    left: calc(50% - 20px);
  }
}

nav.navbar {
  #logo {
    float: left;
    font-size: 34px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    letter-spacing: 3px;

    a,
    a:hover {
      color: #fff;
    }

    img {
      padding: 0;
      margin: 0 10px 0 0;
      height: 34px;
    }
  }

  .right {
    flex: 1;
  }
}


main {
  .page-header {
    position: relative;
    height: 300px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &.has-headline::before {
      content: '';
      background-color: rgba(0, 0, 0, 0.7);
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }

    h1 {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      text-align: center;
      color: #fff;
    }

    img {
      position: absolute;
      left: 50%;
      top: 60px;
      margin-left: -100px;
      width: 200px;
      height: 200px;
    }
  }

  .page-content {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding-top: 20px;
    overflow: hidden;

    &::before {
      content: '';
      background-color: rgba(255, 255, 255, 0.92);
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }
}

#footer {
  background: #000;
  padding: 20px 0;
  color: #eee;
  font-size: 14px;

  .social-links {
    text-align: center;
    margin: 10px 0;

    a {
      font-size: 18px;
      display: inline-block;
      background: #333;
      color: #eee;
      line-height: 1;
      padding: 8px 0;
      margin-right: 4px;
      border-radius: 50%;
      text-align: center;
      width: 36px;
      height: 36px;
      transition: 0.3s;

      &:hover,
      &:focus {
        background: $primary;
        color: #fff
      }
    }
  }

  .meta-menu {
    text-align: center;
    margin: 10px 0;

    a {
      color: #eee;
    }
  }
}

.klaro-button,
.back-to-top {
  position: fixed;
  background: $primary;
  color: #fff;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 1;
  font-size: 16px;
  border-radius: 50%;
  bottom: 15px;
  transition: background 0.5s, opacity 0.5s;
  z-index: 11;

  i {
    padding-top: 14px;
    color: #fff;
  }
}
.klaro-button {
    left: 15px;
}
.back-to-top {
    opacity: 0;
    right: 15px;
}

.toolbox-element {
  position: relative;
  margin-bottom: 20px;

  &.toolbox-heading {
    margin: 0;
  }
}

.member-col,
.news,
.news-full,
.event {
  position: relative;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  overflow: hidden;

  > h4 {
    font-weight: 700;
    font-size: 20px;
  }
}

.member-col {
  text-align: center;

  > .btn {
    border-radius: 0 0 4px 4px;
  }
}

.news-full {
  padding: 5px 10px;
  .body {
    :last-child {
      margin-bottom: 0;
    }
  }
}

a#push-subscription-button {
  font-size: 18px;
  display: inline-block;
  background: #333;
  color: #eee;
  line-height: 1;
  padding: 10px 0;
  margin-right: 10px;
  border-radius: 50%;
  text-align: center;
  width: 40px;
  height: 40px;
  transition: 0.3s;

  &:hover,
  &:focus {
    background: $primary;
    color: #fff
  }
}
.push-notification-alert {
  position: fixed;
  top: 50px;
  left: 10px;
  right: 10px;
}
